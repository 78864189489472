import { useGLTF, Float, Html, Sparkles, OrbitControls } from '@react-three/drei'
import { Physics, RigidBody } from '@react-three/rapier';
import { useEffect, useRef, useState } from 'react';
import { usePlay } from './Play';
import { Perf } from 'r3f-perf';

export default function Experience()
{
    const rog = useGLTF('./rog-draco.glb')
    const phone = useGLTF('./phone.glb')
    const whole = useRef()
    const phoneRef = useRef()
    const cube = useRef()

  
    const {play, setPlay} = usePlay()

    function useIsMobile() {
        const [isMobile, setIsMobile] = useState(false);
      
        useEffect(() => {
          const userAgent = typeof navigator === 'undefined' ? '' : navigator.userAgent;
          const mobile = Boolean(userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i));
          setIsMobile(mobile);
        }, []);
      
        return isMobile;
      }

      const mobile = useIsMobile()
      console.log(mobile);

    useEffect(() => {
        // When the component mounts and whenever 'play' changes...
        if (play) {
          // If 'play' is true, set body overflow to visible
          document.body.style.overflow = 'visible';
          document.getElementById('hiddenContent').style.visibility = 'visible'
        } else {
          // If 'play' is false, set body overflow to hidden
          document.body.style.overflow = 'hidden';
          document.getElementById('hiddenContent').style.visibility = 'hidden'
        }
    
        // Optional: Reset overflow when the component unmounts
        return () => {
          document.body.style.overflow = 'visible';
          document.getElementById('hiddenContent').style.visibility = 'visible'
        };
      }, [play]);
/* 
    const boom = () => {
        console.log(phoneRef.current);
        phoneRef.current.applyImpulse({x: 0, y: 2, z: 0})
        phoneRef.current.applyTorqueImpulse({x: 0, y: 1, z: 1})
    } */

    // Scroll effect
    useEffect(() => {
        
        const handleScroll = () => {
            const scrollY = window.scrollY;
            const totalHeight = document.body.scrollHeight - window.innerHeight;
            const scrollPosition = scrollY / totalHeight;

            // Example: Map scrollPosition to a rotation value (e.g., 0 to 2π)
            const rotation = scrollPosition * Math.PI * 0.1;

            // Apply rotation to the whole group
            if (whole.current) {
                
        console.log(mobile + 'mobile');
                whole.current.rotation.x = rotation *3
                whole.current.rotation.y = -rotation * 0.2
                whole.current.position.z = scrollPosition *3.2 
                mobile ? whole.current.position.y = scrollPosition *2.6 : whole.current.position.y = scrollPosition *8.2
                whole.current.position.x = scrollPosition * -0.4
            }
        };

        window.addEventListener('scroll', handleScroll);

        

        return () => window.removeEventListener('scroll', handleScroll);
    }, [mobile]);

    

    return <> 

    
    <directionalLight color={'#eeffee'}  position={[-4, 10, 10]} intensity={6} />
    <ambientLight intensity={1.5} />
    <Sparkles
    size={ 6 }
    scale={ [ 4, 2, 4 ] }
    position={[ -0.5, 0.8, 2 ]}
    speed={ 0.1 }
    count={ 40 }
/>
    
    <Float speed={1} rotation-y={-0.4} rotationIntensity={0.09} floatIntensity={0.2} scale={0.4} >
        {/* <PresentationControls
            zoom={1}
            rotation={ [ 0.13, 0.1, 0 ] }
            polar={ [ - 0.05, 0.05 ] }
            azimuth={ [ - 0.05, 0.05 ] }> */}
            <group ref={whole}  >
                
            <Physics debug={false} gravity={[0, -9.5, 0]} >
                
                <RigidBody type='fixed' colliders="cuboid">
                
                    <primitive  object={rog.scene} scale={14} position={mobile ? [-1.5, 0.5, 3] : [-4, 1, 8]} rotation-y={- 0.1} >
                    
                        <Html
                        transform
                        wrapperClass="htmlScreen"
                        distanceFactor={ 1.6 }
                        position={ [ 0.004, 0.137, -0.141  ] }
                        rotation-x={ - 0.09 }
                        scale={0.06}
                    
                        > { play ? <iframe src='https://kosteaz.com/' style={{
                            border: 'none',
                            overflow: `${ mobile ? 'hidden' : 'visible' }`
                          }} wmode="Opaque" /> : "" }
                        </Html>  
                    
                    
                    </primitive>   

                
                </RigidBody>
                
                <RigidBody ref={phoneRef}  colliders="cuboid">
                    <group  position={mobile ? [-1, 4.2, 3.5] : [-2, 4.2, 8]} rotation-z={Math.PI * 0.5} rotation-y={Math.PI * -0.5} >
                        <mesh  geometry={phone.nodes.phone.geometry} > <meshBasicMaterial color={'red'}/></mesh>
                        <mesh  geometry={phone.nodes.glass.geometry}> <meshBasicMaterial color={'#202020'} />
                            <Html
                            transform
                            wrapperClass="htmlScreenMobile"
                            distanceFactor={ 1 }
                            position={ [ 0, 0, 0  ] }
                            rotation-y={ Math.PI * 0.5 }
                            scale-x={0.80}
                            scale-y={0.68}
                            >
                        
                        { play ?  <iframe src='https://kosteaz.com/storia' style={{
                            border: 'none',
                            overflow: `${ mobile ? 'hidden' : 'visible' }`
                          }} wmode="Opaque" /> : "" }
                    
                        
                            </Html>  
                        </mesh>
                        <mesh  geometry={phone.nodes.cover.geometry}> <meshStandardMaterial roughness={0.2} color={'#47B965'}/></mesh>
                        
                    </group>
            
                
                </RigidBody>
            </Physics>
            </group>
    </Float>
    {/* <ContactShadows
    position-y={- 0 }
    opacity={ 0.4 }
    scale={ 10 }
    blur={ 0.6 }
/> */}
    </>
}