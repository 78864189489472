import { useProgress } from "@react-three/drei"
import { usePlay } from "./Play";

export const Overlay = () => {

    const {progress} = useProgress();
    const {play, setPlay} = usePlay()

    return (
        <div className={`overlay ${play ? "overlay--disappear" : ""}`} >
            <div className={`loader ${progress === 100 ? "loader--disappear" : ""}` } />
            { progress=== 100 && (
            <div className={`intro ${play ? "intro--disappear" : ""}`} >
                <div className="logo">
                    
                    <div className="spinner">
                        <div className="spinner_image" />
                    </div>
                    </div>
                <button className="explore" onClick={() => { setPlay(true) }}  > 
                    Explore
                </button>
            </div>
            ) }

            
        </div>
        

        
    )
}