import './style.css'
import ReactDOM from 'react-dom/client'
import { Canvas } from '@react-three/fiber'
import Experience from './Experience.jsx'
import { Html, Loader } from '@react-three/drei'
import { Suspense, useRef } from 'react'
import { Overlay } from './Overlay.js'
import { PlayProvider, usePlay } from './Play.js'

const root = ReactDOM.createRoot(document.querySelector('#root'))


root.render(<>
    <PlayProvider>
        
    <Loader />
    <Canvas 
    className="r3f"
    camera={ {
        fov: 45,
        near: 0.1,
        far: 2000,
        position: [ -3, 1.5, 4 ]
    } }
>
     <Suspense > <Experience />  </Suspense> 
     
    </Canvas>
    <Overlay />
    </PlayProvider>
    </>
)